import { BaseService } from '@estee/elc-service';
import type { ServiceInitializer } from '@estee/elc-universal-utils';

const { name, version } = __serviceInfo__;

export class ContentService extends BaseService {
    constructor(serviceInitializer: ServiceInitializer) {
        super(name, version, serviceInitializer);
    }
}

export default {
    contentApiSdk: 'API_SDK',
    configStore: 'CONFIG_STORE',
    httpTransport: 'HTTP_TRANSPORT',
    contentService: 'CONTENT_SERVICE',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    contentBlockViewController: 'CONTENT_BLOCK_VIEW_CONTROLLER',
    contentRepository: 'CONTENT_REPOSITORY',
    productRepository: 'PRODUCT_REPOSITORY',
    contentNode: 'CONTENT_NODE',
    basicToutViewController: 'BASIC_TOUT_VIEW_CONTROLLER',
    vtoFoundationOverlayViewController: 'VTO_FOUNDATION_OVERLAY_VIEW_CONTROLLER',
    videoToutViewController: 'VIDEO_TOUT_VIEW_CONTROLLER',
    videoToutYoutubeViewController: 'VIDEO_TOUT_YOUTUBE_VIEW_CONTROLLER',
    thirdPartyToutViewController: 'THIRD_PARTY_TOUT_VIEW_CONTROLLER',
    vtoFoundationQrCodeOverlayViewController: 'VTO_FOUNDATION_QR_CODE_OVERLAY_VIEW_CONTROLLER',
    logger: 'ELC_LOGGER'
};
